<template>
  <section class="invoice-preview-wrapper" v-if="invoiceData.user">

    <b-row

      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img v-if="invoiceData.user.logo" :src="invoiceData.user.logo">
                  <h4>{{ invoiceData.user.client_name }}</h4>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  <span class="invoice-number"># {{ invoiceData.package.member_package_id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ invoiceData.package.created_at }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!--  
          <hr class="invoice-spacing">


          <b-card-body

            class="invoice-padding pt-0"
          >
        <b-row class="invoice-spacing">

              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{invoiceData.member.en_name}}
                </h6>
                <p class="card-text mb-25">

                </p>
                <p class="card-text mb-25">
                 {{invoiceData.member.address}}
                </p>
                <p class="card-text mb-25">
                  {{invoiceData.member.mobile}}
                </p>
                <p class="card-text mb-0">
                 {{invoiceData.member.email}}
                </p>
              </b-col>


              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
              </b-col>
            </b-row>
          -->
          </b-card-body>
          <b-table-lite
            hover
            :items="items"
            :fields="fields"
          />
          <!-- Invoice Description: Table -->
          <hr />
          <b-card
          no-body
          class="invoice-preview-card"
          >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
            <b-col cols="6">
            <div class="mt-0">
              Remarks :   <nl2br class="tnc" tag="div" v-if="invoiceData.package.remarks !== null" :text="invoiceData.package.remarks" />
              </div>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
              <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      ${{invoiceData.package.amount}}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      $0.00
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Transaction Fee:
                    </p>
                    <p class="invoice-total-amount">
                      $0.00
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      ${{invoiceData.package.amount}}
                    </p>
                  </div>

                </div>

            </b-col>
            </b-row>
          </b-card-body>
          </b-card>


          <hr class="invoice-spacing">
              <b-card-body class="invoice-padding pt-0">
                <div class="font-weight-bold text-center mb-2">TERMS AND CONDITIONS</div>
                <nl2br class="tnc" tag="div" :text="invoiceData.user.pt_terms" />
                 <b-row class="mt-2">
                  <b-col cols="9">
                <VueSignaturePad
                  ref="signaturePad"
                  :options="{ dotSize: 2, throttle: 16,backgroundColor: 'rgba(250,250,250,1)',onBegin, onEnd }"
                />

            </b-col>
            <b-col cols="3">
                <b-button
                class="mb-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                @click="save()"
                variant="primary"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                class="mb-1"
                block
                @click="clear()"
                variant="outline-warning"
              >
                Empty
    </b-button>
            </b-col>
            </b-row>

             </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import Nl2br from 'vue-nl2br'
import VueSignaturePad from 'vue-signature-pad';
import VueHtml2pdf from 'vue-html2pdf'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    VueHtml2pdf,
    BRow,
    Nl2br,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink
  },
  data() {
    return {
      invoiceData : {},
      items:[],
      signature:null,
      fields:['package_name','package_type','quantity','expire_at']
    }
  },
  created() {
    this.get_invoice_data()
  },
  methods : {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
      html2pdf().outputPdf().then(function(pdf) {
        console.log(btoa(pdf))
      });
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      //console.log(isEmpty)
      //console.log(data)
      this.signature = data
      this.upload_signature(data)
      //this.generateReport()
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },

    onBegin() {
      console.log('=== Begin ===');
    },
    onEnd() {
      console.log('=== End ===');
    },
    get_invoice_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/epad/'+router.currentRoute.params.id+'/')
        .then(res => {
          this.invoiceData = res.data
          this.items.push(this.invoiceData.package)
        })
    },
    upload_signature(signature) {
      this.$http.post(process.env.VUE_APP_API_BASE+'/epad/'+router.currentRoute.params.id+'/signature/', {"signature" : signature} )
        .then(res => {
          if (res.data.result === true) {
            this.$router.push(`/apps/member/edit/`+this.invoiceData.member.member_id+`/`)
          }
       //   this.invoiceData = res.data
       //
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  .tnc {
    line-height:2rem;
  }
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
